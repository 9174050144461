const LOCATIONS = [
  {
    label: 'Arizona',
    slug: 'az',
    regions: [
      {
        label: 'Central Phoenix',
        slug: 'central-Phoenix',
      },
      {
        label: 'North Phoenix',
        slug: 'north-Phoenix',
      },
      {
        label: 'West Phoenix',
        slug: 'west-Phoenix',
      },
      {
        label: 'East Valley',
        slug: 'east-valley',
      },
      {
        label: 'Tucson & Southern AZ',
        slug: 'tucson-and-southern-az',
      },
      {
        label: 'Flagstaff & Northern AZ',
        slug: 'Flagstaff-and-northern-az',
      },
    ],
  },
  {
    label: 'California (North)',
    slug: 'norcal',
    regions: [
      {
        label: 'Greater San Jose and Palo Alto Area',
        slug: 'greater-san-jose-and-palo-alto-area',
      },
      {
        label: 'Oakland and East Bay',
        slug: 'oakland-and-east-bay',
      },
      {
        label: 'Sacramento, Fairfield, Dixon, Davis, Vacaville, Stockton, Modesto, Tracy, Galt, Lodi and Manteca',
        slug: 'sacramento-fairfield-dixon-davis-vacaville-stocketon-modesto-tracy-galt-lodi-and-manteca',
      },
      {
        label: 'Greater SF Area and the Peninsula',
        slug: 'greater-sf-area-and-the-peninsula',
      },
    ],
  },
  {
    label: 'California (South)',
    slug: 'socal',
    regions: [
      {
        label: 'Los Angeles and Orange County',
        slug: 'los-angeles-and-orange-county',
      },
      {
        label: 'Central and West Los Angeles Region',
        slug: 'central-and-west-los-angeles-region',
      },
      {
        label: 'San Diego County',
        slug: 'san-diego-county',
      },
    ],
  },
  {
    label: 'Michigan',
    slug: 'mi',
    regions: [
      {
        label: 'Greater Detroit',
        slug: 'greater-detroit',
      },
      {
        label: 'Walled Lake, Evart, Honor, Negaunee, Big Rapids and Adrian',
        slug: 'walled-lake-evart-honor-negaunee-big-rapids-and-adrian',
      },
      {
        label: 'Greater Sterling Heights',
        slug: 'greater-sterling-heights',
      },
      {
        label: 'Great Battle Creek Region',
        slug: 'great-battle-Creek-region',
      },
    ],
  },
  {
    label: 'Nevada',
    slug: 'nv',
    regions: [
      {
        label: 'Greater Las Vegas Area',
        slug: 'greater-las-vegas-area',
      },
    ],
  },
];

const PARTNERS = [
  {
    name: 'Airfield',
    logo: '/partners/airfield.jpg',
    website: 'https://www.airfieldsupplyco.com/orderonline',
    regions: ['greater-san-jose-and-palo-alto-area'],
    states: ['norcal'],
  },
  {
    name: 'Harborside San Jose',
    logo: '/partners/harborside.png',
    website: 'https://www.shopharborside.com/harborside-san-jose-menu-order-online/',
    regions: ['greater-san-jose-and-palo-alto-area'],
    states: ['norcal'],
  },
  {
    name: 'Harborside Oakland',
    logo: '/partners/harborside.png',
    website: 'https://www.shopharborside.com/harborside-oakland-menu-order-online/',
    regions: ['oakland-and-east-bay'],
    states: ['norcal'],
  },
  {
    name: 'Humble Root',
    logo: '/partners/humble-root.png',
    website: 'https://humbleroot.com/menu/?_sfm_brandName=Select%20Oil&_sfm__price=0+345&orderby=date',
    regions: ['sacramento-fairfield-dixon-davis-vacaville-stocketon-modesto-tracy-galt-lodi-and-manteca'],
    states: ['norcal'],
  },
  {
    name: 'Cananbis Express (CanEx)',
    logo: '/partners/thumbnail_greenRush.png',
    website: 'https://www.greenrush.com/brand/select',
    regions: ['greater-sf-area-and-the-peninsula'],
    states: ['norcal'],
  },
  {
    name: 'Curaleaf Midtown',
    logo: '/partners/curaleaf.png',
    website: 'https://shop-midtownaz.curaleaf.com/',
    regions: ['central-Phoenix'],
    states: ['az'],
  },
  {
    name: 'GrassDoor',
    logo: '/partners/grassdoor.png',
    website: 'https://grassdoor.com/shop',
    regions: ['los-angeles-and-orange-county'],
    states: ['socal'],
  },
  {
    name: 'Grassp',
    logo: '/partners/thumbnail_Grasp_Health.png',
    website: 'https://www.grasspit.com/',
    regions: ['central-Phoenix', 'north-Phoenix', 'west-Phoenix', 'east-valley'],
    states: ['az'],
  },
  {
    name: 'Greenpharms',
    logo: '/partners/thumbnail_green_pharms.png',
    website: 'https://greenpharms.com/delivery/',
    regions: ['east-valley'],
    states: ['az'],
  },
  {
    name: 'Erba Market',
    logo: '/partners/erba.png',
    website: 'https://www.erbamarkets.com/order-online',
    regions: ['central-and-west-los-angeles-region'],
    states: ['socal'],
  },
  {
    name: 'March and Ash',
    logo: '/partners/march-and-ash.png',
    website: 'https://marchandash.com/product/select-oil-vape-cartridges',
    regions: ['san-diego-county'],
    states: ['socal'],
  },
  {
    name: 'Urbn Leaf',
    logo: '/partners/urbn-leaf.png',
    website: 'https://shop.urbnleaf.com/promo/urbn-leaf-san-diego',
    regions: ['san-diego-county'],
    states: ['socal'],
  },
  {
    name: 'Urbana',
    logo: '/partners/thumbnail_Urbana_lockup.png',
    website: 'https://urbananow.com/delivery',
    regions: ['greater-sf-area-and-the-peninsula'],
    states: ['norcal'],
  },
  {
    name: 'Jars',
    logo: '/partners/jars.png',
    website: 'https://www.jarscannabis.com/jars-at-metrocenter/menu/',
    regions: ['central-Phoenix', 'north-Phoenix'],
    states: ['az'],
  },
  {
    name: 'SWC',
    logo: '/partners/swc.png',
    website: 'https://swcarizona.com/psc-express/',
    regions: ['east-valley', 'Flagstaff-and-northern-az'],
    states: ['az'],
  },
  {
    name: 'Supurb',
    logo: '/partners/superb.png',
    website: 'https://www.supurb.com/',
    regions: ['central-Phoenix', 'north-Phoenix', 'west-Phoenix', 'east-valley'],
    states: ['az'],
  },
  {
    name: 'Territory',
    logo: '/partners/territory.png',
    website: 'https://territorydispensary.com/order-online/',
    regions: ['east-valley'],
    states: ['az'],
  },
  {
    name: 'Oasis',
    logo: '/partners/oasis.png',
    website: 'https://www.oasisdispensaries.net/order-online/',
    regions: ['east-valley', 'west-Phoenix'],
    states: ['az'],
  },
  {
    name: 'Utopia Gardens',
    logo: '/partners/utopia-gardens.png',
    website: 'https://utopiagardens.com/menu/',
    regions: ['greater-detroit'],
    states: ['mi'],
  },
  {
    name: 'Lume',
    logo: '/partners/lume.png',
    website: 'https://www.lume.com/delivery/',
    regions: ['walled-lake-evart-honor-negaunee-big-rapids-and-adrian'],
    states: ['mi'],
  },
  {
    name: 'Liv',
    logo: '/partners/liv.png',
    website: 'https://weedmaps.com/deliveries/liv-delivery-3',
    regions: ['greater-sterling-heights'],
    states: ['mi'],
  },
  {
    name: 'Great Lakes Holistics',
    logo: '/partners/great-lakes-holistics.png',
    website: 'https://weedmaps.com/deliveries/great-lakes-holistics-2',
    regions: ['great-battle-Creek-region'],
    states: ['mi'],
  },
  {
    name: 'Curaleaf Las Vegas',
    logo: '/partners/curaleaf.png',
    website: 'https://curaleaf.com/blackjack-collective-menu/',
    regions: ['greater-las-vegas-area'],
    states: ['nv'],
  },
  {
    name: 'Acres Cannabis',
    logo: '/partners/acres.png',
    website: 'https://curaleaf.com/acres-cannabis-menu/',
    regions: ['greater-las-vegas-area'],
    states: ['nv'],
  },
  {
    name: 'Eaze',
    logo: '/partners/eaze.png',
    website: 'https://www.eaze.com/',
    regions: ['greater-san-jose-and-palo-alto-area', 'oakland-and-east-bay', 'sacramento-fairfield-dixon-davis-vacaville-stocketon-modesto-tracy-galt-lodi-and-manteca', 'greater-sf-area-and-the-peninsula', 'los-angeles-and-orange-county', 'central-and-west-los-angeles-region', 'san-diego-county'],
    states: ['norcal', 'socal'],
  },
  {
    name: 'Flower Co',
    logo: '/partners/flowerco.png',
    website: 'https://flowercompany.com/',
    regions: ['greater-san-jose-and-palo-alto-area', 'oakland-and-east-bay', 'sacramento-fairfield-dixon-davis-vacaville-stocketon-modesto-tracy-galt-lodi-and-manteca', 'greater-sf-area-and-the-peninsula', 'los-angeles-and-orange-county', 'central-and-west-los-angeles-region', 'san-diego-county'],
    states: ['norcal', 'socal'],
  },
];

module.exports = {
  LOCATIONS,
  PARTNERS,
};
