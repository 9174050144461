import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../components/Layout';
import Seo from '../components/SEO';
import DeliveryView from '../components/DeliveryView/DeliveryView';

import '../styles/global.scss';

const DeliveryPage = ({location, pageContext: { state, region}}) => {
  let pageTitle = 'Delivery';

  if (state && !region) {
    pageTitle = `Delivery options for ${state.label}`;
  } else if (state && region) {
    pageTitle = `Delivery options for ${region.label}`;
  }


  return (
    <Layout view="delivery">
      <Seo title={pageTitle} />
      <DeliveryView path={location.pathname} state={state} region={region} />
    </Layout>
  );
};

DeliveryPage.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object,
};

export default DeliveryPage;