import React from 'react';
import PropTypes from 'prop-types';

const DeliveryMenu = ({
  filter, locations, path, stateChangeHandler,
}) => {
  const statePath = path.split('/')[2];

  function menuChangeHandler(e) {
    stateChangeHandler(e.target.value);
  }

  return (
    <div className="delivery-menu">
      <label htmlFor="filter-menu">
        Select a state
        <select
          onChange={menuChangeHandler}
          onBlur={menuChangeHandler}
          value={filter.state || statePath}
        >
          {locations.map((state) => (
            <option
              value={state.slug}
              key={state.slug}
            >
              {state.label}
            </option>
          ))}
        </select>
      </label>
    </div>
  );
};

DeliveryMenu.propTypes = {
  filter: PropTypes.shape({
    region: PropTypes.string,
    state: PropTypes.string,
  }),
  locations: PropTypes.array.isRequired,
  path: PropTypes.string.isRequired,
  stateChangeHandler: PropTypes.func.isRequired,
};

export default DeliveryMenu;
