import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'gatsby';

const DeliverySidebar = ({
  filter,
  locations,
  path,
  regionChangeHandler,
  stateChangeHandler,
}) => {
  // Auto expand based on the URL, if this is a direct link
  const statePath = path.split('/')[2];

  return (
    <div className="delivery-sidebar">
      <h2 className="delivery-sidebar__title">Select Your State.</h2>
      {locations.map((state) => (
        <div className="delivery-sidebar__state" key={state.slug}>
          <h3>
            <Link
              aria-controls={`${state.slug}-region`}
              aria-expanded={filter.state === state.slug || statePath === state.slug}
              className="delivery-sidebar__state-title"
              id={`${state.slug}-button`}
              onClick={(e) => {
                e.preventDefault();
                stateChangeHandler(state.slug);
              }}
              to={`/delivery/${state.slug}`}
            >
              {state.label}
            </Link>
          </h3>
          <div
            aria-labelledby={`${state.slug}-button`}
            className="delivery-sidebar__region"
            hidden={filter.state !== state.slug && statePath !== state.slug}
            id={`${state.slug}-region`}
          >
            <ol className="delivery-sidebar__list">
              {state.regions.map((region) => {
                const regionClasses = cx({
                  'delivery-sidebar__link': true,
                  'delivery-sidebar__link--active': filter.region === region.slug,
                });
                return (
                  <li className="delivery-sidebar__list-item" key={region.slug}>
                    <Link
                      activeClassName="delivery-sidebar__link--active"
                      className={regionClasses}
                      onClick={(e) => {
                        e.preventDefault();
                        regionChangeHandler(region.slug);
                      }}
                      to={`/delivery/${state.slug}/${region.slug}`}
                    >
                      {region.label}
                    </Link>
                  </li>
                );
              })}
            </ol>
          </div>
        </div>
      ))}
    </div>
  );
};

DeliverySidebar.propTypes = {
  filter: PropTypes.shape({
    region: PropTypes.string,
    state: PropTypes.string,
  }),
  locations: PropTypes.array.isRequired,
  path: PropTypes.string.isRequired,
  regionChangeHandler: PropTypes.func.isRequired,
  stateChangeHandler: PropTypes.func.isRequired,
};

export default DeliverySidebar;
