import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const DeliveryContent = ({ filter, partners }) => {
  let statePartners = [];
  if (filter && filter.state) {
    statePartners = partners.filter((partner) => partner.states.includes(filter.state));
  }

  // All of the partners have a regions property that can handle
  // multiple regions
  let filteredPartners = [];
  if (filter && filter.region) {
    filteredPartners = partners.filter((partner) => partner.regions.includes(filter.region));
  }

  return (
    <div className="delivery-content">

      {statePartners.length === 0
      && <div className="delivery-content__empty">
        Select a state to explore our delivery partners
      </div>}

      <ol className="delivery-content__list">

        {/* Leafly is special and should show on every page */}
        {statePartners.length !== 0
        && <li className="delivery-content__item">
          <a
            className="delivery-content__link"
            href="https://www.leafly.com/finder?filter=pickup&filter=delivery"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img className="delivery-content__image" src="/partners/leafly.png" alt="Leafly" />
            <h3 className="delivery-content__partner-name">Leafly</h3>
          </a>
        </li>}
        {/* Leafly is special and should show on every page */}

        {statePartners.map((partner, index) => {
          const itemClasses = cx({
            'delivery-content__item': true,
            'delivery-content__item--filtered': filter && filter.region && !filteredPartners.includes(partner),
          });
          return (
            // eslint-disable-next-line react/no-array-index-key
            <li className={itemClasses} key={`partner-${index}`}>
              <a
                className="delivery-content__link"
                href={partner.website}
                rel="noopener noreferrer"
                target="_blank"
              >
                {partner.logo
                && <img className="delivery-content__image" src={partner.logo} alt={partner.name} />}
                <h3 className="delivery-content__partner-name">{partner.name}</h3>
              </a>
            </li>
          );
        })}

      </ol>
    </div>
  );
};

DeliveryContent.propTypes = {
  filter: PropTypes.shape({
    region: PropTypes.string,
    state: PropTypes.string,
  }),
  partners: PropTypes.array.isRequired,
};

export default DeliveryContent;
