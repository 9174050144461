import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { LOCATIONS, PARTNERS } from '../../data/delivery';

import Hero from '../Hero/Hero';
import DeliverySidebar from './DeliverySidebar';
import DeliveryMenu from './DeliveryMenu';
import DeliveryContent from './DeliveryContent';

import background from './assets/background.jpg';
import background2x from './assets/background@2x.jpg';
import './DeliveryView.scss';

const DEFAULT_STATE_SLUG = LOCATIONS[0].slug;

const DeliveryView = ({ path, region, state }) => {
  const [heroImage, setHeroImage] = useState(background);
  const [activeState, setActiveState] = useState(
    (state && state.slug) || DEFAULT_STATE_SLUG,
  );
  const [activeRegion, setActiveRegion] = useState(region && region.slug);

  // Not using the withMedia HOC because matchMedia is better
  const mobileMq = window.matchMedia('(max-width: 768px)');
  const updateHeroImage = useCallback(e => {
    setHeroImage(e && e.matches ? background : background2x);
  }, []);

  useEffect(() => {
    try {
      mobileMq.addEventListener('change', updateHeroImage, { passive: false });
    } catch (e) {
      mobileMq.addListener(updateHeroImage);
    }
    return () => {
      try {
        mobileMq.removeEventListener('change', updateHeroImage, {
          passive: false,
        });
      } catch (e) {
        mobileMq.removeListener(updateHeroImage);
      }
    };
  });

  // Filtering using state, for progressive handling of the sidebar navigation
  // The sidebar has links for users without JS, but we are stopping those links
  // manually filtering the state and region using the container / child pattern.

  // Make sure that active states match this filterObject AND the path, for both
  // cases.
  const filterObject = {
    region: activeRegion,
    state: activeState,
  };
  function stateChangeHandler(stateSlug) {
    setActiveState(stateSlug);
    setActiveRegion(null);
  }
  function regionChangeHandler(regionSlug) {
    setActiveRegion(regionSlug);
  }

  return (
    <article className="DeliveryView">
      <Hero
        background={heroImage}
        title="Get Select Delivered."
        secondTitle={ 
          <a className="hero__link" href="https://www.selectcannabis.delivery">
            Order Direct from Select
          </a>
        }
        secondDescription={
          <>
            <a className="hero__link" href="https://www.selectcannabis.delivery">
              Los Angeles and San Francisco Metro Area only
            </a>
            <br />
            Or try our partners below
          </>  
        }
        description={
          <>
            Enjoy the convenience of delivery.
            <br />
            Ahhh, that’s better
          </>
        }
        position="top"
      />
      <div className="DeliveryView__content">
        <DeliverySidebar
          filter={filterObject}
          locations={LOCATIONS}
          path={path}
          regionChangeHandler={regionChangeHandler}
          stateChangeHandler={stateChangeHandler}
        />
        <DeliveryMenu
          filter={filterObject}
          locations={LOCATIONS}
          path={path}
          stateChangeHandler={stateChangeHandler}
        />
        <DeliveryContent filter={filterObject} partners={PARTNERS} />
      </div>
    </article>
  );
};

DeliveryView.propTypes = {
  path: PropTypes.string.isRequired,
  region: PropTypes.object,
  state: PropTypes.object,
};

export default DeliveryView;
